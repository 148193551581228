/* For colors see : 
    http://www.color-hex.com/color-palette/
      - 37498 : you are blue as a night in Cub
  dark blue #1   #410066 	
  blue #2     	 #490d73 	
	blue #3        #4d1a80 	
	blue #4        #40268c 	
  blue #5        #333399

  lightbluegrey #7070b7
  
  Additionnal colors
  - yellow #f1d923
  - dark red #f0150a


  Material design #2 green && yellow . Thanks too https://www.color-hex.com/color-palette/3713
  #259b24 	(37,155,36)
	#8bc34a 	(139,195,74)
	#ffeb3b 	(255,235,59)
	#ffc107 	(255,193,7)
	#ff9800 	(255,152,0)

  */

.UnusedStyleToShowColors {
  /* Simple trick so that we can directly have an overview of the various 
  colors we use in the IDE */
  background: linear-gradient(to top right, #333399, #40268c, #4d1a80, #490d73, #410066, #7070b7, #f5f5ff, #f1d923, #f0150a, #9c27b0);
}

/* html { 
  background: url(https://41.sinou.org/files/images/tract-4.6.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */

/*, html, overflow-x: hidden;*/

/* :global(:root) { */
  /* --highlight-color: #259b24; */

:root {
  --global-highlight-color: #124d12;
  --global-highlight-light: #8bc34a;
  --global-background-light: #e9f5e9;
  --global-background-very-light: #ffffff;
  --global-text-color: #030F03;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, body {
  font-family: "Fira Sans", "Roboto", "Helvetica", "Arial", sans-serif;
}

h1, h2, h3, h4, h5 {
  color: var(--global-highlight-color);
  font-weight: 400; 
}

h6 {
  font-size: 1.1em;
  color: var(--global-text-color);
  font-weight: 300; 
  font-style: italic;
}

body {
  background-color: var(--global-background-very-light);
  margin: auto;
  line-height: 1.6em;
  font-weight: 300;
  color: var(--global-text-color);
}

strong {
  font-weight: 500;
}

@media (max-width: 991px) {
  body, html {
    position: relative;
    overflow-x: hidden;
  }
}